import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormContext } from '../../../../utils/context/contextFactory';
import { FormState } from '../../../../utils/state/initialStateFactory';

export type OnDialogClose = () => void;

export function createOnDialogCloseAction({
  getControllerState,
}: ActionFactoryParams<FormState, FormContext>): OnDialogClose {
  return () => {
    const [, setState] = getControllerState();

    setState({
      dialog: undefined,
    });
  };
}

import { CreateActionParams } from './../actions';
import { CouponsErrorType } from '../../../../types/errors';
import { CouponInfo } from '../../../../types/coupons';

export type RemoveCoupon = () => void;

export function createRemoveCouponAction(
  actionFactoryParams: CreateActionParams,
): RemoveCoupon {
  return async () => {
    const [state, setState] = actionFactoryParams.getControllerState();
    const { errorHandlers } = actionFactoryParams.internalActions;

    errorHandlers.clearErrorByTypes([CouponsErrorType]);

    setState({
      couponInfo: getCouponInfoWithoutAppliedCoupon(state.couponInfo),
    });
  };
}

export function getCouponInfoWithoutAppliedCoupon(couponInfo: CouponInfo) {
  return {
    areCouponsAvailable: couponInfo.areCouponsAvailable,
    isCouponInputDisplayed: couponInfo.isCouponInputDisplayed,
  };
}

import { BookErrorType } from './../../../../types/errors';
import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  FormState,
  FormStatus,
} from '../../../../utils/state/initialStateFactory';
import { ErrorHandlers } from '../errorHandlers/errorHandlers';
import { getCouponInfoWithoutAppliedCoupon } from '../removeCoupon/removeCoupon';

export type CalculatePaymentDetails = (coupon?: string) => void;

export function createCalculatePaymentDetailsAction({
  actionFactoryParams,
  errorHandlers
}:{
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>,
  errorHandlers: ErrorHandlers,
}): CalculatePaymentDetails {
  return async (coupon) => {
    const [state, setState] = actionFactoryParams.getControllerState();
    const { formApi } = actionFactoryParams.context;
    const { numberOfParticipants, slotAvailability, service } = state;
    const { id: serviceId, rate } = service;
    const slot = slotAvailability?.slot!;
    try {
      const couponCode = coupon || state.couponInfo.appliedCoupon?.couponCode;
      const paymentsDetails = await formApi.getPaymentsDetails({
        slot,
        numberOfParticipants,
        rate,
        serviceId,
        couponCode,
      });
      const appliedCoupon = paymentsDetails?.couponDetails;
      setState({
        couponInfo: {
          ...getCouponInfoWithoutAppliedCoupon(state.couponInfo),
          appliedCoupon,
        },
      });
    } catch (error) {
      errorHandlers.addError(error as BookErrorType);
    }
    setState({
      status: FormStatus.IDLE,
    });
  };
}

import {
  FormStatus,
} from '../../../../utils/state/initialStateFactory';
import { CreateActionParams } from '../actions';

export type SetNumberOfParticipants = (numberOfParticipants: number) => void;

export function createSetNumberOfParticipantsAction(
  actionFactoryParams: CreateActionParams,
): SetNumberOfParticipants {
  return async (numberOfParticipants: number) => {
    const [, setState] = actionFactoryParams.getControllerState();
    const { calculatePaymentDetails } = actionFactoryParams.internalActions;
    setState({
      numberOfParticipants,
      status: FormStatus.PROCESSING_PAYMENT_DETAILS,
    });

    calculatePaymentDetails();
  };
}

import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  FormState,
  FormStatus,
} from '../../../../utils/state/initialStateFactory';
import { FormContext } from '../../../../utils/context/contextFactory';

export type InitializeWidget = () => void;

export function createInitializeWidgetAction({
  getControllerState,
}: ActionFactoryParams<FormState, FormContext>): InitializeWidget {
  return () => {
    const [, setState] = getControllerState();

    setState({
      status: FormStatus.IDLE,
    });
  };
}

import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  FormState,
  FormStatus,
} from '../../../../utils/state/initialStateFactory';
import { FormContext } from '../../../../utils/context/contextFactory';

export type PromptLogin = () => void;

export function createPromptLoginAction({
  getControllerState,
  context: { wixSdkAdapter },
}: ActionFactoryParams<FormState, FormContext>): PromptLogin {
  return async () => {
    const [, setState] = getControllerState();
    try {
      setState({
        status: FormStatus.PROCESSING_USER_DETAILS,
      });
      await wixSdkAdapter.promptUserLogin();
    } catch (e) {
      setState({
        status: FormStatus.IDLE,
      });
    }
  };
}

import { GenericErrorType } from '../../../../types/errors';
import { CreateActionParams } from '../actions';

export type OnToastClose = () => void;

export function createOnToastCloseAction(
  actionParams: CreateActionParams,
): OnToastClose {
  return () => {
    const { errorHandlers } = actionParams.internalActions;
    errorHandlers.clearErrorByTypes([GenericErrorType]);
  };
}

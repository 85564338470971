import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';

export type OnToggleCoupon = () => void;

export function createOnToggleCouponAction(
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>,
): OnToggleCoupon {
  return async () => {
    const [state, setState] = actionFactoryParams.getControllerState();

    setState({
      couponInfo: {
        ...state.couponInfo,
        isCouponInputDisplayed: !state.couponInfo.isCouponInputDisplayed,
      },
    });
  };
}

import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';
import { FormState } from '../../../../utils/state/initialStateFactory';
import { FormContext } from '../../../../utils/context/contextFactory';

export type OnLogout = () => void;

export function createOnLogoutAction({
  context: { wixSdkAdapter },
  getControllerState,
}: ActionFactoryParams<FormState, FormContext>): OnLogout {
  return async () => {
    const [, setState] = getControllerState();
    wixSdkAdapter.removeFromSessionStorage(BookingsQueryParams.FILLED_FIELDS);
    setState({
      overideDefaultFieldsValues: true,
    });
  };
}

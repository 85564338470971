import { CouponsErrorType, GenericErrorType } from '../../../../types/errors';
import {
  FormStatus,
} from '../../../../utils/state/initialStateFactory';
import { CreateActionParams } from '../actions'
export type SetCoupon = (couponCode: string) => void;

export function createSetCouponAction(
actionFactoryParams: CreateActionParams,
): SetCoupon {
  return async (couponCode: string) => {
    const [, setState] = actionFactoryParams.getControllerState();
    const { calculatePaymentDetails, errorHandlers } = actionFactoryParams.internalActions;
    errorHandlers.clearErrorByTypes([CouponsErrorType, GenericErrorType]);
    if (couponCode) {
      setState({
        status: FormStatus.PROCESSING_COUPON,
      });
      calculatePaymentDetails(couponCode);
    }
    setState({
      status: FormStatus.IDLE,
    });
  };
}
